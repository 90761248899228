<script setup>
const siteConfig = useSiteConfig();

</script>

<template>
<div class="grid grid-cols-1 md:flex md:justify-between gap-6 md:gap-4 text-sm">
  <div class="flex flex-col gap-6 md:gap-4 font-light">
    <div class="uppercase">Oferta</div>
    <div class="flex flex-col gap-2">
      <NuxtLink :to="{name:'static-offer-entrepreneur'}">Leasing dla firm</NuxtLink>
      <NuxtLink :to="{name:'static-offer-consumer'}">Leasing konsumencki</NuxtLink>
      <NuxtLink :to="{name:'static-insurances'}">Ubezpieczenia VEHIS</NuxtLink>
    </div>
  </div>

  <div class="flex flex-col gap-6 md:gap-4 font-light">
    <div class="uppercase">O nas</div>
    <div class="flex flex-col gap-2">
      <NuxtLink :to="useBackend().getDownloadLinkForFile('services')" external>Partnerzy serwisowi</NuxtLink>
    </div>
  </div>

  <div class="flex flex-col gap-6 md:gap-4 font-light">
    <div class="uppercase">Kontakt</div>
    <div class="flex flex-col gap-2">
      <div>Biuro Obsługi Klienta</div>
      <div class="flex gap-2 items-center"><IconPhone stroke="#000000" /> {{ siteConfig.bokPhone }}</div>
      <div class="flex gap-2 items-center"><IconMail class="!w-5 !h-5"/> {{ siteConfig.contactEmail }}</div>
    </div>
  </div>

  <div class="flex flex-col gap-6 md:gap-4 font-light">
    <div>Jesteśmy członkiem</div>
    <div class="-mt-4">
      <VectorZplLogo class="md:w-[140px]"/>
    </div>
  </div>
</div>
</template>
