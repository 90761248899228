<script setup>

</script>

<template>
  <Container gray no-paddings>
    <div class="py-6">
      <AppFooterMainLinks />
      <AppFooterSocialMedia />
    </div>
  </Container>
  <AppFooterLinks />
</template>
