<script setup>
const props = defineProps({

  forceColumn: {
    type: Boolean,
    default: false
  },
  clientPortal: {
    type: Boolean,
    default: () => true
  }
})
const route = useRoute();

</script>

<template>
  <div class="flex flex-col gap-6 "
       :class="[{'md:flex-row md:justify-between border-t border-t-gray-400 mt-6 pt-6':!forceColumn}]">
       <div v-if="clientPortal">
      <ButtonRounded class="bg-sad-pink border-sad-pink max-w-48 min-w-48"
                     href="https://klient.vehis.pl"
                     target="_blank"
                     with-icon><IconUser class="mr-2"/> Portal Klienta</ButtonRounded>
    </div>
    <div class="flex flex-col justify-center"
         :class="[{'md:flex-row md:gap-2 md:items-center':!forceColumn}]">
      <div class="font-extralight text-sm">Obserwuj nas:</div>
      <div class="flex gap-2 mt-4 md:mt-0" :class="[{'justify-between': forceColumn}]">
        <a href="https://www.facebook.com/vehispl" rel="nofollow" target="_blank" class="inline-block cursor-pointer">
          <VectorSoMeFb />
        </a>
        <a href="https://www.instagram.com/vehis_cars/" rel="nofollow" target="_blank">
          <VectorSoMeIg />
        </a>
        <a href="https://www.linkedin.com/company/vehis" rel="nofollow" target="_blank">
          <VectorSoMeLn />
        </a>
        <a href="https://www.youtube.com/@VEHIS" rel="nofollow" target="_blank">
          <VectorSoMeYt />
        </a>
        <NuxtLink v-if="!route.path.includes('moto') && !noVehisLogos && !forceColumn" to="/moto" class="pt-1">
          <VectorMotoVehis />
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
