<script setup>

</script>

<template>
  <svg width="75" height="34" viewBox="0 0 75 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.47775 0.65625L7.41919 9.46654L11.2834 0.65625H14.1428L14.6065 14.9537H11.9016L11.5925 5.21596L8.19203 13.0989H6.41451L2.93676 5.29325L2.62763 14.9537H0L0.540983 0.65625H3.47775Z" fill="#374151"/>
    <path d="M18.3164 7.84191C18.3164 3.51405 21.5623 0.5 25.7356 0.5C29.9089 0.5 33.0775 3.59133 33.0775 7.84191C33.0775 12.0925 29.9089 15.2611 25.6583 15.2611C21.485 15.1838 18.3164 12.0925 18.3164 7.84191ZM30.1407 7.84191C30.1407 4.98243 28.2859 2.97306 25.6583 2.97306C23.108 2.97306 21.1759 4.98243 21.1759 7.84191C21.1759 10.7014 23.0307 12.7108 25.6583 12.7108C28.2859 12.7108 30.1407 10.6241 30.1407 7.84191Z" fill="#374151"/>
    <path d="M49.0742 3.12931H44.9009V14.9537H42.0415V3.12931H37.8682V0.65625H49.0742V3.12931Z" fill="#374151"/>
    <path d="M53.7891 7.84191C53.7891 3.51405 57.035 0.5 61.2083 0.5C65.3816 0.5 68.5502 3.59133 68.5502 7.84191C68.5502 12.0925 65.3816 15.2611 61.131 15.2611C56.9577 15.1838 53.7891 12.0925 53.7891 7.84191ZM65.6907 7.84191C65.6907 4.98243 63.8359 2.97306 61.2083 2.97306C58.6579 2.97306 56.7258 4.98243 56.7258 7.84191C56.7258 10.7014 58.5806 12.7108 61.2083 12.7108C63.8359 12.7108 65.6907 10.6241 65.6907 7.84191Z" fill="#374151"/>
    <path d="M37.0185 30.0248L39.1052 24.7695H39.878L37.4049 30.7976H36.6321L34.2363 24.7695H35.0092L37.0185 30.0248Z" fill="black"/>
    <path d="M43.5874 30.9513C41.8872 30.9513 40.4961 29.8694 40.4961 27.86C40.4961 25.9279 41.8099 24.6914 43.4329 24.6914C45.1331 24.6914 46.2151 26.0052 46.2151 27.7055C46.2151 27.86 46.2151 28.0146 46.2151 28.0919H41.1916C41.2689 29.483 42.119 30.3331 43.5874 30.3331C44.283 30.3331 44.824 30.1012 45.4422 29.7921L45.7514 30.2558C45.2104 30.7195 44.4375 30.9513 43.5874 30.9513ZM45.5968 27.4736C45.5968 26.3916 44.824 25.3097 43.5101 25.3097C42.2736 25.3097 41.3462 26.2371 41.2689 27.4736H45.5968Z" fill="black"/>
    <path d="M52.475 30.8742V27.4737C52.475 26.2372 51.934 25.3871 50.6975 25.3871C49.461 25.3871 48.4563 26.2372 48.4563 27.3964V30.8742H47.7607V21.9866L48.4563 21.832V25.7735C48.9973 25.0006 49.7701 24.6142 50.6975 24.6142C52.2432 24.6142 53.1706 25.5416 53.1706 27.0873V30.7196H52.475V30.8742Z" fill="black"/>
    <path d="M54.7939 22.8379C54.7939 22.5287 55.0258 22.2969 55.3349 22.2969C55.6441 22.2969 55.8759 22.5287 55.8759 22.8379C55.8759 23.147 55.6441 23.3788 55.3349 23.3788C55.0258 23.3788 54.7939 23.147 54.7939 22.8379ZM55.0258 24.8472H55.7213V30.8753H55.0258V24.8472Z" fill="black"/>
    <path d="M59.1218 30.3322C59.8174 30.3322 60.7448 30.1004 60.7448 29.2503C60.7448 27.7046 57.3443 28.4774 57.3443 26.3135C57.3443 25.1543 58.349 24.6133 59.4309 24.6133C60.1265 24.6133 60.7448 24.8451 61.2857 25.1543L60.9766 25.618C60.5902 25.3861 60.0492 25.2315 59.5082 25.2315C58.8127 25.2315 58.0398 25.4634 58.0398 26.2362C58.0398 27.8592 61.4403 27.0091 61.4403 29.173C61.4403 30.3322 60.4356 30.8732 59.1991 30.8732C58.2717 30.8732 57.6534 30.5641 57.0352 30.1777L57.4216 29.714C57.9626 30.1004 58.5035 30.3322 59.1218 30.3322Z" fill="black"/>
    <path d="M62.7539 30.4121C62.7539 30.1029 62.9858 29.8711 63.2949 29.8711C63.604 29.8711 63.8359 30.1029 63.8359 30.4121C63.8359 30.7212 63.604 30.9531 63.2949 30.9531C62.9858 30.9531 62.7539 30.7212 62.7539 30.4121Z" fill="black"/>
    <path d="M66.3088 25.8507C66.8498 25.0778 67.6226 24.6914 68.55 24.6914C69.9411 24.6914 71.4868 25.6188 71.4868 27.7827C71.4868 29.9467 70.0184 30.9513 68.55 30.9513C67.6226 30.9513 66.8498 30.5649 66.3088 29.7921V33.3471L65.6133 33.5017V24.846H66.2315L66.3088 25.8507ZM68.55 25.3097C67.2362 25.3097 66.3088 26.3144 66.3088 27.86C66.3088 29.4057 67.3135 30.3331 68.55 30.3331C69.9411 30.3331 70.7913 29.2511 70.7913 27.7827C70.714 26.3144 69.8639 25.3097 68.55 25.3097Z" fill="black"/>
    <path d="M72.9561 21.9866L73.6516 21.832V29.2512C73.6516 29.8695 73.7289 30.2559 74.038 30.3332L73.9607 30.8742C73.4198 30.8742 72.9561 30.4878 72.9561 29.6376V21.9866Z" fill="black"/>
    <path d="M71.2549 13.8699C71.2549 13.0198 71.9504 12.3242 72.8005 12.3242C73.6507 12.3242 74.3462 13.0198 74.3462 13.8699C74.3462 14.72 73.6507 15.4155 72.8005 15.4155C71.8731 15.4155 71.2549 14.72 71.2549 13.8699Z" fill="#DB1A21"/>
  </svg>
</template>
